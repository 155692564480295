<template>
    <div class="ym-echart-comp">
        <div :id="id" :style="'width:'+ width+ ';height:'+height"></div>
    </div>
</template>

<script>
import elementResizeDetectorMaker from 'element-resize-detector'
// 引入 ECharts 主模块
// const echarts = require('echarts')
const echarts = require('echarts/lib/echarts')
// 引入第三方地图
const worldJson = require('./map/json/world.json')
echarts.registerMap('world', worldJson)

require('echarts/theme/macarons') // echarts theme
// 引入柱状图
require('echarts/lib/chart/bar')
// 引入折线图
require('echarts/lib/chart/line')
// 引入饼图
require('echarts/lib/chart/pie')
// 引入提示框组件
require('echarts/lib/component/tooltip')
// 引入标题
require('echarts/lib/component/title')
// 引入数据集
require('echarts/lib/component/dataset')
// 引入散点图
require('echarts/lib/chart/scatter')
// 引入路径图
require('echarts/lib/chart/lines')
// 引入雷达图
// require('./lib/chart/radar')
// 引入地图,主要用于地理区域数据的可视化，配合 visualMap 组件用于展示不同区域的人口分布密度等数据。
// require('./lib/chart/map')
// 引入树图
// require('./lib/chart/tree')
// 引入矩形树图
// require('./lib/chart/treemap')
// 引入关系图
// require('./lib/chart/graph')
// 引入仪表盘
// require('./lib/chart/gauge')
// 引入漏斗图
// require('./lib/chart/funnel')
// 引入桑基图
// require('./lib/chart/sankey')
// 引入盒须图
// require('./lib/chart/boxplot')
// 引入K线图
// require('./lib/chart/candlestick')
// 引入带有涟漪特效动画的散点图
// require('./lib/chart/effectScatter')
// 引入热力图
// require('./lib/chart/heatmap')
// 引入象形柱图
// require('./lib/chart/pictorialBar')
// 引入主题河流图
// require('./lib/chart/themeRiver')
// 引入旭日图
// require('./lib/chart/sunburst')
// 引入自定义系列
// require('./lib/chart/custom')
// 引入直角坐标系内绘图网格
// require('./lib/component/grid')
// 引入极坐标系
// require('./lib/component/polar')
// 引入地理坐标/地图
// require('./lib/component/geo')
// 引入单轴，可以被应用到散点图中展现一维数据
// require('./lib/component/singleAxis')
// 引入平坐标系
// require('./lib/component/parallel')
// 引入日历坐标系
// require('./lib/component/calendar')
// 引入原生图形元素组件
// require('./lib/component/graphic')
// 引入工具栏。内置有导出图片，数据视图，动态类型切换，数据区域缩放，重置五个工具。
// require('./lib/component/toolbox')
// 引入坐标轴指示器
// require('./lib/component/axisPointer')
// 引入区域选择组件
// require('./lib/component/brush')
// timeline 组件，提供了在多个 ECharts option 间进行切换、播放等操作的功能
// require('./lib/component/timeline')
// 引入图表标注
// require('./lib/component/markPoint')
// 引入图表标线
// require('./lib/component/markLine')
// 引入图表标域
// require('./lib/component/markArea')
// 引入图例组件
// require('./lib/component/legend')
// 引入图例相关
// require('./lib/component/legendScroll')
// 用于区域缩放的组件
// require('./lib/component/dataZoom')
// 内置型数据区域缩放组件
// require('./lib/component/dataZoomInside')
// 引入滑动条型数据区域缩放组件
// require('./lib/component/dataZoomSlider')
// 引入视觉映射组件，用于进行『视觉编码』，也就是将数据映射到视觉元素（视觉通道）。
// require('./lib/component/visualMap')
// 引入连续型视觉映射组件
// require('./lib/component/visualMapContinuous')
// 引入分段型视觉映射组件
// require('./lib/component/visualMapPiecewise')
// 引入 VML 渲染器模块，供ie8使用，echarts默认使用Canvas 绘制图表， ECharts v4.0 发布了 SVG 渲染器，可选择'canvas' 或 'svg'指定渲染器
// require('zrender/lib/vml/vml')
// 引入 SVG 渲染器模块
// require('zrender/lib/svg/svg')

export default {
  name: 'Echart',
  props: {
    width: {
      default: '100%'
    },
    height: {
      default: '100%'
    },
    options: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      chart: null,
      id: 'chart' + Math.ceil(Math.random() * 10) * Math.ceil(Math.random() * 1000)
    }
  },
  computed: {},
  watch: {
    options: {
      handler() {
        this.update()
      },
      deep: true,
      immediate: true
    }
  },
  created() {
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      // let _this = this
      // 基于准备好的dom，初始化echarts实例
      if (this.chart) {
        echarts.dispose(this.chart)
      }
      this.chart = echarts.init(document.getElementById(this.id))
      // 绘制图表
      this.chart.setOption(this.options)
      // 响应屏幕大小重绘
      this.watchSize()
      // window.addEventListener('resize', function () {
      //   _this.chart.resize()
      // })
    },
    update() {
      if (this.chart) {
        // 更新配置
        this.chart.setOption(this.options)
      }
    },
    // 监听外部包裹元素变化
    watchSize() {
      const _this = this
      let erd = elementResizeDetectorMaker()
      erd.listenTo(document.getElementById(_this.id), (element) => {
        _this.$nextTick(() => { // 这里填写监听改变后的操作
          _this.chart.resize()
        })
      })
    }
  }
}
</script>

<style lang="scss">
    .ym-echart-comp {
        width: 100%;
        height: 82%;
        position: relative;
    }
</style>
